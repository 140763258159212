package hu.simplexion.adaptive.site

import hu.simplexion.adaptive.resource.DrawableResource

private object CommonMainDrawable0 {
  public val deep_waters: DrawableResource by 
      lazy { init_deep_waters() }

  public val getting_started: DrawableResource by 
      lazy { init_getting_started() }

  public val github_mark: DrawableResource by 
      lazy { init_github_mark() }

  public val impressum: DrawableResource by 
      lazy { init_impressum() }

  public val menu: DrawableResource by 
      lazy { init_menu() }

  public val preview_status: DrawableResource by 
      lazy { init_preview_status() }

  public val server: DrawableResource by 
      lazy { init_server() }

  public val tools: DrawableResource by 
      lazy { init_tools() }

  public val ui: DrawableResource by 
      lazy { init_ui() }

  public val what_is_adaptive: DrawableResource by 
      lazy { init_what_is_adaptive() }
}

public val Res.drawable.deep_waters: DrawableResource
  get() = CommonMainDrawable0.deep_waters

private fun init_deep_waters(): DrawableResource = hu.simplexion.adaptive.resource.DrawableResource(
  "drawable:deep_waters",
    setOf(
      hu.simplexion.adaptive.resource.ResourceItem(setOf(),
    "adaptiveResources/hu.simplexion.adaptive.site/drawable/deep-waters.webp", -1, -1),
    )
)

public val Res.drawable.getting_started: DrawableResource
  get() = CommonMainDrawable0.getting_started

private fun init_getting_started(): DrawableResource =
    hu.simplexion.adaptive.resource.DrawableResource(
  "drawable:getting_started",
    setOf(
      hu.simplexion.adaptive.resource.ResourceItem(setOf(),
    "adaptiveResources/hu.simplexion.adaptive.site/drawable/getting-started.webp", -1, -1),
    )
)

public val Res.drawable.github_mark: DrawableResource
  get() = CommonMainDrawable0.github_mark

private fun init_github_mark(): DrawableResource = hu.simplexion.adaptive.resource.DrawableResource(
  "drawable:github_mark",
    setOf(
      hu.simplexion.adaptive.resource.ResourceItem(setOf(),
    "adaptiveResources/hu.simplexion.adaptive.site/drawable/github-mark.svg", -1, -1),
    )
)

public val Res.drawable.impressum: DrawableResource
  get() = CommonMainDrawable0.impressum

private fun init_impressum(): DrawableResource = hu.simplexion.adaptive.resource.DrawableResource(
  "drawable:impressum",
    setOf(
      hu.simplexion.adaptive.resource.ResourceItem(setOf(),
    "adaptiveResources/hu.simplexion.adaptive.site/drawable/impressum.webp", -1, -1),
    )
)

public val Res.drawable.menu: DrawableResource
  get() = CommonMainDrawable0.menu

private fun init_menu(): DrawableResource = hu.simplexion.adaptive.resource.DrawableResource(
  "drawable:menu",
    setOf(
      hu.simplexion.adaptive.resource.ResourceItem(setOf(),
    "adaptiveResources/hu.simplexion.adaptive.site/drawable/menu.svg", -1, -1),
    )
)

public val Res.drawable.preview_status: DrawableResource
  get() = CommonMainDrawable0.preview_status

private fun init_preview_status(): DrawableResource =
    hu.simplexion.adaptive.resource.DrawableResource(
  "drawable:preview_status",
    setOf(
      hu.simplexion.adaptive.resource.ResourceItem(setOf(),
    "adaptiveResources/hu.simplexion.adaptive.site/drawable/preview-status.webp", -1, -1),
    )
)

public val Res.drawable.server: DrawableResource
  get() = CommonMainDrawable0.server

private fun init_server(): DrawableResource = hu.simplexion.adaptive.resource.DrawableResource(
  "drawable:server",
    setOf(
      hu.simplexion.adaptive.resource.ResourceItem(setOf(),
    "adaptiveResources/hu.simplexion.adaptive.site/drawable/server.webp", -1, -1),
    )
)

public val Res.drawable.tools: DrawableResource
  get() = CommonMainDrawable0.tools

private fun init_tools(): DrawableResource = hu.simplexion.adaptive.resource.DrawableResource(
  "drawable:tools",
    setOf(
      hu.simplexion.adaptive.resource.ResourceItem(setOf(),
    "adaptiveResources/hu.simplexion.adaptive.site/drawable/tools.webp", -1, -1),
    )
)

public val Res.drawable.ui: DrawableResource
  get() = CommonMainDrawable0.ui

private fun init_ui(): DrawableResource = hu.simplexion.adaptive.resource.DrawableResource(
  "drawable:ui",
    setOf(
      hu.simplexion.adaptive.resource.ResourceItem(setOf(),
    "adaptiveResources/hu.simplexion.adaptive.site/drawable/ui.webp", -1, -1),
    )
)

public val Res.drawable.what_is_adaptive: DrawableResource
  get() = CommonMainDrawable0.what_is_adaptive

private fun init_what_is_adaptive(): DrawableResource =
    hu.simplexion.adaptive.resource.DrawableResource(
  "drawable:what_is_adaptive",
    setOf(
      hu.simplexion.adaptive.resource.ResourceItem(setOf(),
    "adaptiveResources/hu.simplexion.adaptive.site/drawable/what-is-adaptive.webp", -1, -1),
    )
)
