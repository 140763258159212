package hu.simplexion.adaptive.site

import hu.simplexion.adaptive.resource.getResourceUri
import hu.simplexion.adaptive.resource.readResourceBytes
import kotlin.ByteArray
import kotlin.String

public object Res {
  /**
   * Reads the content of the resource file at the specified path and returns it as a byte array.
   *
   * Example: `val bytes = Res.readBytes("files/key.bin")`
   *
   * @param path The path of the file to read in the adaptive resource's directory.
   * @return The content of the file as a byte array.
   */
  public suspend fun readBytes(path: String): ByteArray =
      readResourceBytes("adaptiveResources/hu.simplexion.adaptive.site/" + path)

  /**
   * Returns the URI string of the resource file at the specified path.
   *
   * Example: `val uri = Res.getUri("files/key.bin")`
   *
   * @param path The path of the file in the adaptive resource's directory.
   * @return The URI string of the file.
   */
  public fun getUri(path: String): String =
      getResourceUri("adaptiveResources/hu.simplexion.adaptive.site/" + path)

  public object drawable

  public object string

  public object array

  public object plurals

  public object font

  public object `file`
}
